// NOTE: use api-fetch instead <PZ 2020-10-20>
import { ajax } from './utils'
import { BASE_API_URL } from './endpoints'

const DEFAULT_OPTIONS = {
  version: 1,
}

/**
 * @typedef {Object.<string,any>} ApiOptions
 * @property {Object.<string,any>} [data] - data to send as request body
 * @property {number} [version] - API version to use
 * @property {Function} [success] - success callback function
 * @property {Function()} [error] - error callback function
 */

/**
 * Generic Sketch API request function used by exported functions
 * @param {string} endpoint - request endpoint
 * @param {ApiOptions} options - API options
 * @param {string} options.type - request type
 * @returns {XMLHttpRequest}
 */
function request(endpoint, options) {
  const mergedOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  }
  const url = `${BASE_API_URL}${mergedOptions.version}${endpoint}`
  return ajax({ ...mergedOptions, url })
}

/**
 * Make a GET request to the Sketch API
 * @param {string} endpoint - API endpoint
 * @param {ApiOptions} options - request options
 * @returns {XMLHttpRequest}
 */
function get(endpoint, options) {
  return request(endpoint, { ...options, type: 'GET' })
}

/**
 * Make a POST request to the Sketch API
 * @param {string} endpoint - API endpoint
 * @param {ApiOptions} options - request options
 * @returns {XMLHttpRequest}
 */
function post(endpoint, options) {
  return request(endpoint, { ...options, type: 'POST' })
}

/**
 * Make a PUT request to the Sketch API
 * @param {string} endpoint - API endpoint
 * @param {ApiOptions} options - request options
 * @returns {XMLHttpRequest}
 */
function put(endpoint, options) {
  return request(endpoint, { ...options, type: 'PUT' })
}

/**
 * Make a DELETE request to the Sketch API
 * @param {string} endpoint - API endpoint
 * @param {ApiOptions} options - request options
 * @returns {XMLHttpRequest}
 */
function del(endpoint, options) {
  return request(endpoint, { ...options, type: 'DELETE' })
}

export default {
  get,
  post,
  put,
  del,
}
