//
//
// Appeltaart 🍰 is our take on removing the need of jQuery
//
// Making a tiny "jQuery", utility methods that will
// make up to the majority of the functionality we need
//
//

/**
 * Find an element within another element, or the document
 * @param {string} selector - the selector to look for
 * @return {NodeList}
 */
const find = function(selector) {
  if (this instanceof Element) {
    return this.querySelectorAll(selector)
  }
  return document.querySelectorAll(selector)
}

//
// Utility Methods
//

// Extend: https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/
var extend = function(...args) {
  // Variables
  var extended = {}
  var deep = false
  var i = 0
  var length = args.length

  // Check if a deep merge
  if (Object.prototype.toString.call(args[0]) === '[object Boolean]') {
    deep = args[0]
    i++
  }

  // Merge the object into the extended object
  var merge = function(obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extend(true, extended[prop], obj[prop])
        } else {
          extended[prop] = obj[prop]
        }
      }
    }
  }

  // Loop through each object and conduct a merge
  for (; i < length; i++) {
    var obj = args[i]
    merge(obj)
  }

  return extended
}

find.extend = extend

//
// Serialize an object to a string
// Custom, based on: https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
//
var serialize = function(obj, prefix) {
  var str = []
  Object.keys(obj).forEach(function(p) {
    var k = prefix ? prefix + '[' + p + ']' : p
    var v = obj[p]
    str.push(
      v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v)
    )
  })
  return str.join('&')
}

find.serialize = serialize

//
// Ajax request
//
var ajax = function(opts) {
  var defaults = {
    type: 'GET',
    url: '',
    data: null,
  }
  var options = extend(true, defaults, opts)

  // create the http request
  var xhr = new window.XMLHttpRequest()

  if (options.type == 'GET' && options.data) {
    options.url += serialize(options.data)
  } else if (options.type == 'POST' && options.data) {
    options.data = serialize(options.data)
  }

  xhr.open(options.type, options.url)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.send(options.data)

  // deal with the response
  xhr.onreadystatechange = function() {
    var DONE = 4 // done
    var ERROR = 400 // error
    // var OK = 200 // success
    var responseContentType = this.getResponseHeader('content-type')
    var result = null

    if (xhr.readyState === DONE) {
      if (xhr.status < ERROR && options.success) {
        if (responseContentType == 'application/json') {
          result = JSON.parse(xhr.responseText)
        } else {
          result = xhr.responseText
        }
        options.success(result, xhr.status)
      } else if (options.error) {
        options.error(xhr.status, xhr.responseText)
      }
    }
  }

  return xhr
}

//
//
//
find.ajax = ajax

// Toggling a className
// https://www.jamestease.co.uk/blether/add-remove-or-toggle-classes-using-vanilla-javascript
var toggleClass = function(className, force = undefined) {
  var el = this
  el.classList.toggle(className, force)
  return this
}

var switchClass = function(from, to) {
  var el = this
  el.removeClass(from).addClass(to)
  return this
}

var on = function(ev, method) {
  this.addEventListener(ev, method)
  return this
}

var trigger = function(ev, data) {
  var event = new window.CustomEvent(ev, {
    detail: data,
  })
  this.dispatchEvent(event)
  return this
}

var off = function(ev, method) {
  this.removeEventListener(ev, method)
  return this
}

var hasClass = function(className) {
  var spacedClassName = ' ' + className + ' '
  var rep = ' ' + this.className + ' '.replace(/[\n\t]/g, ' ')
  return rep.indexOf(spacedClassName) > -1
}

// forEach method, could be shipped as part of an Object Literal/Module
// https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
var forEach = function(array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]) // passes back stuff we need
  }
}

var each = function(func) {
  func(0, this)
  return this
}

var attr = function(attribute, val) {
  if (typeof val == 'string' || typeof val == 'number') {
    return this.setAttribute(attribute, val)
  }
  return this.getAttribute(attribute)
}

var text = function(val) {
  if (val) {
    this.textContent = val
  }
  return this.textContent
}

var toggle = function() {
  var display = window.getComputedStyle(this).display
  if (display == 'none') {
    display = 'block'
  } else {
    display = 'none'
  }
  this.style.display = display
  return this
}

var addClass = function(className) {
  var classes = className.split(' ')
  var classesLen = classes.length
  for (var i = 0; i < classesLen; i++) {
    this.classList.add(classes[i])
  }
  return this
}

var removeClass = function(className) {
  var classes = className.split(' ')
  var classesLen = classes.length
  for (var i = 0; i < classesLen; i++) {
    if (this !== undefined && this.classList !== undefined) this.classList.remove(classes[i])
  }
  return this
}

var removeAttr = function(attribute) {
  this.removeAttribute(attribute)
  return this
}

var append = function(child) {
  this.appendChild(child)
}

var css = function(attribute, value) {
  this.style[attribute] = value
  return this
}

var inDocument = function() {
  return document.body.contains(this)
}

var parent = function() {
  return this.parentElement
}

// var index = function() {
//   var children = this.parentNode.childNodes
//   var num = 0
//   for (var i = 0; i < children.length; i++) {
//     if (children[i] == this) return num
//     if (children[i].nodeType == 1) num++
//   }
//   return -1
// }
var index = function(el) {
  var len = this.length || 0
  var ind = false
  var num = 0
  var children
  var i

  if (el) {
    for (i = 0; i < len; i++) {
      if (this[i] == el) {
        ind = i
      }
    }
  } else {
    children = this.parentNode.childNodes
    for (i = 0; i < children.length; i++) {
      if (children[i] == this) return num
      if (children[i].nodeType == 1) num++
    }
    return -1
  }

  return ind
}

var parents = function(selector) {
  var el = this
  do {
    el = el.parentElement
  } while (!el.hasClass(selector) && document.body !== el)
  return el
}

// based on https://stackoverflow.com/questions/11805955/how-to-get-the-distance-from-the-top-for-an-element
var offset = function() {
  var element = this
  var xPosition = 0
  var yPosition = 0

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft
    yPosition += element.offsetTop - element.scrollTop + element.clientTop
    element = element.offsetParent
  }

  return {
    left: xPosition,
    top: yPosition,
  }
}

var get = function(i) {
  var len = this.length
  var el = false
  if (typeof len == 'number' && i < len) {
    el = this[i]
  } else if (!len && i === 0) {
    el = this
  }
  return el
}

//
// Element attach event & callback
//
Element.prototype.on = on

NodeList.prototype.on = function(ev, method) {
  forEach(this, function(idx, el) {
    el.on(ev, method)
  })
  return this
}

Element.prototype.trigger = trigger

NodeList.prototype.trigger = function(ev, data) {
  forEach(this, function(idx, el) {
    el.trigger(ev, data)
  })
  return this
}

//
// Element toggle classname
//
Element.prototype.toggleClass = toggleClass

NodeList.prototype.toggleClass = function(className) {
  forEach(this, function(idx, el) {
    el.toggleClass(className)
  })
  return this
}

//
// Element switch classname from one to another
//
Element.prototype.switchClass = switchClass

NodeList.prototype.switchClass = function(from, to) {
  forEach(this, function(idx, el) {
    el.switchClass(from, to)
  })
  return this
}

//
// Element has class?
//
Element.prototype.hasClass = hasClass

NodeList.prototype.hasClass = function(className) {
  return this[0].classList.contains(className)
}

//
// Toggle
//
Element.prototype.toggle = toggle

NodeList.prototype.toggle = function() {
  forEach(this, function(idx, el) {
    el.toggle()
  })
  return this
}

//
// Add Class
//
Element.prototype.addClass = addClass

NodeList.prototype.addClass = function(className) {
  forEach(this, function(idx, el) {
    el.addClass(className)
  })
  return this
}

//
// Remove Class
//
Element.prototype.removeClass = removeClass

NodeList.prototype.removeClass = function(className) {
  forEach(this, function(idx, el) {
    el.removeClass(className)
  })
  return this
}

//
// Remove Attribute
//
Element.prototype.removeAttr = removeAttr

NodeList.prototype.removeAttr = function(attribute) {
  forEach(this, function(idx, el) {
    el.removeAttr(attribute)
  })
  return this
}

//
// Each
//
Element.prototype.each = each

NodeList.prototype.each = function(func) {
  forEach(this, function(idx, el) {
    func(index, el)
  })
  return this
}

//
// Attribute
//
Element.prototype.attr = attr

NodeList.prototype.attr = function(attribute, val) {
  var attributes = []
  forEach(this, function(idx, el) {
    attributes.push(el.attr(attribute, val))
  })
  return attributes
}

//
// Text
//
Element.prototype.text = text

//
// update node text, return the joined text of every element
// optional paramenter is the separator ( a|b|c )
//
NodeList.prototype.text = function(val, join) {
  var all = []
  var allText

  forEach(this, function(idx, el) {
    // update the node text
    el.text(val)

    all.push(el.text())
  })

  allText = all.join(join || '')

  return allText
}

//
// Append element
//
Element.prototype.append = append

NodeList.prototype.append = function(child) {
  forEach(this, function(idx, el) {
    el.append(child)
  })
  return this
}

//
// Find element
//
Element.prototype.find = find

NodeList.prototype.find = function() {
  // var list
  return false
}

//
// Css attributes
//
Element.prototype.css = css

NodeList.prototype.css = function(attribute, value) {
  forEach(this, function(idx, el) {
    el.css(attribute, value)
  })
  return this
}

//
// Remove element
//
NodeList.prototype.remove = function() {
  forEach(this, function(idx, el) {
    el.remove()
  })
  return this
}

//
// in DOM
//
Element.prototype.inDocument = inDocument

//
// remove event
//
Element.prototype.off = off

NodeList.prototype.off = function(ev, method) {
  forEach(this, function(idx, el) {
    el.off(ev, method)
  })
  return off
}

//
// parent element
//
Element.prototype.parent = parent

NodeList.prototype.parent = function() {
  return this[0].parent
}

//
// get Element index
//
Element.prototype.index = index
NodeList.prototype.index = function() {
  return this[0].index()
}

//
// offset Element
//
Element.prototype.offset = offset

NodeList.prototype.offset = function() {
  return this[0].offset()
}

Element.prototype.parents = parents

NodeList.prototype.parents = function(selector) {
  return this[0].parents(selector)
}

NodeList.prototype.index = index

Element.prototype.get = get
NodeList.prototype.get = get

export default find
